import React, { useRef } from 'react'
import TriggerAnimation from './Trigger'
import './animations.css'

function FadeIn(props) {
    const ref = useRef()
    const animate = TriggerAnimation(ref, '-50px')

    return (
        <div className={ animate ? 'fade-in' : 'before-fade-in'} ref={ ref }>
            { props.children }
        </div>
    )
}

export default FadeIn