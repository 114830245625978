import React from "react";
import FadeIn from "../components/FadeIn";
import Footer from "../components/footer";
import Slider from "../components/sliders/Slider";

class Projects extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dialogOpen: false,
      dialogID: null,
      words: "",
    };
  }

  openDialog = (value) => {
    this.setState({ dialogOpen: true, dialogID: value });
  };

  handleClose = () => {
    this.setState({ dialogOpen: false, dialoID: null });
  };

  setWords = (value) => {
    this.setState({ words: value });
  };

  render() {
    return (
      <>
        <FadeIn>
          <img
            className="image-cover"
            src="/images/Projects Cover.jpg"
            alt=""
          />
        </FadeIn>
        <div className="gradient-1">
          <div className="row-buildings">
            <h5>Buildings</h5>
            <div className="buildings-wrapper" id="building">
              <button onClick={() => this.openDialog("liminal")}>
                <div className="buildings-card">
                  <img src="/images/denver/denverCover.jpg" alt="denver" />
                  <div className="buildings-card-text">
                    <p>
                      Santa Fe Arts District
                      <br />
                      Denver, CO
                    </p>
                  </div>
                </div>
              </button>
              <button onClick={() => this.openDialog("jefferson")}>
                <div className="buildings-card">
                  <img
                    src="/images/brooklyn/jeffersonCover.jpg"
                    alt="brooklyn"
                  />
                  <div className="buildings-card-text">
                    <p>
                      Bed-Stuy
                      <br />
                      Brooklyn, NY
                    </p>
                  </div>
                </div>
              </button>
              <button onClick={() => this.openDialog("edgecombe")}>
                <div className="buildings-card">
                  <img
                    src="/images/manhattan/edgecombeCover.jpg"
                    alt="edgecombe"
                  />
                  <div className="buildings-card-text">
                    <p>
                      Washington Heights
                      <br />
                      Manhattan, NY
                    </p>
                  </div>
                </div>
              </button>
              <button onClick={() => this.openDialog("linden")}>
                <div className="buildings-card">
                  <img src="/images/bushwick/lindenCover.jpg" alt="linden" />
                  <div className="buildings-card-text">
                    <p>
                      Bushwick
                      <br />
                      Brooklyn, NY
                    </p>
                  </div>
                </div>
              </button>
              <button onClick={() => this.openDialog("suydam")}>
                <div className="buildings-card">
                  <img
                    src="/images/ridgewood/Enscape_2021-08-30-12-29-16.png"
                    alt="suydam"
                  />
                  <div className="buildings-card-text">
                    <p>
                      Ridgewood
                      <br />
                      Brooklyn, NY
                    </p>
                  </div>
                </div>
              </button>
              <button onClick={() => this.openDialog("halsey")}>
                <div className="buildings-card">
                  <img src="/images/halsey/halseyCover.png" alt="halsey" />
                  <div className="buildings-card-text">
                    <p>
                      Bushwick
                      <br />
                      Brooklyn, NY
                    </p>
                  </div>
                </div>
              </button>
            </div>
            <div></div>
          </div>
          {this.state.dialogOpen === true && (
            <>
              {this.state.dialogID === "liminal" && (
                <div className="popup-box">
                  <div className="box">
                    <span className="close-icon" onClick={this.handleClose}>
                      x
                    </span>
                    <div className="popup-wrapper">
                      <div className="quick-facts-wrapper">
                        <h6>Santa Fe Arts District, Denver, CO</h6>
                        <div className="slider-quick-facts">
                          <p>Quick Facts</p>
                          <hr />
                          <ul>
                            <li>New Ground Up Development</li>
                            <li>72 Keys</li>
                            <li>38,097 sqft</li>
                          </ul>
                          <p className="red">Permitting</p>
                        </div>
                      </div>
                      <div className="slider-container">
                        <Slider data={DenverSlider} />
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {this.state.dialogID === "jefferson" && (
                <div className="popup-box">
                  <div className="box">
                    <span className="close-icon" onClick={this.handleClose}>
                      x
                    </span>
                    <div className="popup-wrapper">
                      <div className="quick-facts-wrapper">
                        <h6>Bed-Stuy, Brooklyn, NY</h6>
                        <div className="slider-quick-facts">
                          <p>Maison Jefferson</p>
                          <hr />
                          <ul>
                            <li>Complete Renovation</li>
                            <li>27 Keys</li>
                            <li>7,055 sqft</li>
                          </ul>
                          <p className="green">Open</p>
                        </div>
                      </div>
                      <div className="slider-container">
                        <Slider data={BrooklynSlider} />
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {this.state.dialogID === "edgecombe" && (
                <div className="popup-box">
                  <div className="box">
                    <span className="close-icon" onClick={this.handleClose}>
                      x
                    </span>
                    <div className="popup-wrapper">
                      <div className="quick-facts-wrapper">
                        <h6>Washington Heights, Manhattan, NY</h6>
                        <div className="slider-quick-facts">
                          <p>Maison Edgecombe</p>
                          <hr />
                          <ul>
                            <li>Complete Renovation</li>
                            <li>27 Keys</li>
                            <li>9,645 sqft</li>
                          </ul>
                          <p className="orange">Under Construction</p>
                        </div>
                      </div>
                      <div className="slider-container">
                        <Slider data={ManhattanSlider} />
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {this.state.dialogID === "linden" && (
                <div className="popup-box">
                  <div className="box">
                    <span className="close-icon" onClick={this.handleClose}>
                      x
                    </span>
                    <div className="popup-wrapper">
                      <div className="quick-facts-wrapper">
                        <h6>Bushwick, Brooklyn, NY</h6>
                        <div className="slider-quick-facts">
                          <p>Maison Linden</p>
                          <hr />
                          <ul>
                            <li>Complete Renovation</li>
                            <li>18 Keys</li>
                            <li>6,252 sqft</li>
                          </ul>
                          <p className="orange">Under Construction</p>
                        </div>
                      </div>
                      <div className="slider-container">
                        <Slider data={BushwickSlider} />
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {this.state.dialogID === "suydam" && (
                <div className="popup-box">
                  <div className="box">
                    <span className="close-icon" onClick={this.handleClose}>
                      x
                    </span>
                    <div className="popup-wrapper">
                      <div className="quick-facts-wrapper">
                        <h6>Ridgewood, Brooklyn, NY</h6>
                        <div className="slider-quick-facts">
                          <p>Maison Syudam</p>
                          <hr />
                          <ul>
                            <li>Complete Renovation</li>
                            <li>18 Keys</li>
                            <li>5,992 sqft</li>
                          </ul>
                          <p className="orange">Under Construction</p>
                        </div>
                      </div>
                      <div className="slider-container">
                        <Slider data={RidgewoodSlider} />
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {this.state.dialogID === "halsey" && (
                <div className="popup-box">
                  <div className="box">
                    <span className="close-icon" onClick={this.handleClose}>
                      x
                    </span>
                    <div className="popup-wrapper">
                      <div className="quick-facts-wrapper">
                        <h6>Bushwick, Brooklyn, NY</h6>
                        <div className="slider-quick-facts">
                          <p>Maison Halsey</p>
                          <hr />
                          <ul>
                            <li>Complete Renovation</li>
                            <li>18 Keys</li>
                            <li>5,992 sqft</li>
                          </ul>
                          <p className="orange">Under Construction</p>
                        </div>
                      </div>
                      <div className="slider-container">
                        <Slider data={HalseySlider} />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
        <Footer active="5" />
      </>
    );
  }
}

export default Projects;

const DenverSlider = [
  {
    image: "/images/denver/denverCover.jpg",
    index: 0,
  },
  {
    image: "/images/denver/denver1.jpg",
    index: 1,
  },
  {
    image: "/images/denver/denver2.jpg",
    index: 2,
  },
  {
    image: "/images/denver/denver3.jpg",
    index: 3,
  },
];
const BrooklynSlider = [
  {
    image: "/images/brooklyn/jeffersonCover.jpg",
    index: 0,
  },
  {
    image: "/images/brooklyn/jefferson1.jpg",
    index: 1,
  },
  {
    image: "/images/brooklyn/jefferson2.jpg",
    index: 2,
  },
  {
    image: "/images/brooklyn/jefferson3.jpg",
    index: 3,
  },
  {
    image: "/images/brooklyn/jefferson4.jpg",
    index: 4,
  },
  {
    image: "/images/brooklyn/jefferson5.jpg",
    index: 5,
  },
];
const ManhattanSlider = [
  {
    image: "/images/manhattan/edgecombeCover.jpg",
    index: 0,
  },
  {
    image: "/images/manhattan/edgecombe1.png",
    index: 1,
  },
  {
    image: "/images/manhattan/edgecombe2.png",
    index: 2,
  },
  {
    image: "/images/manhattan/edgecombe3.png",
    index: 3,
  },
  {
    image: "/images/manhattan/edgecombe4.png",
    index: 4,
  },
];
const BushwickSlider = [
  {
    image: "/images/bushwick/lindenCover.jpg",
    index: 0,
  },
  {
    image: "/images/bushwick/linden1.jpg",
    index: 1,
  },
  {
    image: "/images/bushwick/linden2.jpg",
    index: 2,
  },
  {
    image: "/images/bushwick/linden3.jpg",
    index: 3,
  },
  {
    image: "/images/bushwick/linden4.jpg",
    index: 4,
  },
];
const HalseySlider = [
  {
    image: "/images/halsey/halseyCover.png",
    index: 0,
  },
  {
    image: "/images/halsey/halsey1.png",
    index: 1,
  },
  {
    image: "/images/halsey/halsey2.png",
    index: 2,
  },
  {
    image: "/images/halsey/halsey3.png",
    index: 3,
  },
  {
    image: "/images/halsey/halsey4.png",
    index: 4,
  },
];
const RidgewoodSlider = [
  {
    image: "/images/ridgewood/Enscape_2021-08-29-20-07-00.png",
    index: 0,
  },
  {
    image: "/images/ridgewood/Enscape_2021-08-29-20-36-01.png",
    index: 1,
  },
  {
    image: "/images/ridgewood/Enscape_2021-08-30-12-15-41.png",
    index: 2,
  },
  {
    image: "/images/ridgewood/Enscape_2021-08-30-12-29-16.png",
    index: 3,
  },
  {
    image: "/images/ridgewood/Enscape_2021-08-30-14-30-54.png",
    index: 4,
  },
  {
    image: "/images/ridgewood/Enscape_2021-08-30-14-31-56.png",
    index: 5,
  },
  {
    image: "/images/ridgewood/IMG_5854.JPG",
    index: 6,
  },
  {
    image: "/images/ridgewood/IMG_5856.JPG",
    index: 7,
  },
  {
    image: "/images/ridgewood/IMG_5862.JPG",
    index: 8,
  },
];
