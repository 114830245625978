import React, { useState, useEffect } from "react";
import "./header.css";
import { useLocation } from "react-router-dom";

function Heading() {
  const [toggle, setToggle] = useState("closed");
  const toggleMenu = () => {
    if (toggle === "opened") {
      setToggle("closed");
    } else {
      setToggle("opened");
    }
  };

  const path = useLocation().pathname;

  const [scrolled, setScrolled] = useState("/images/Logo Off White.png");
  const [menuColor, setColor] = useState("white");

  window.onscroll = function () {
    if (path !== "/redtech") {
      if (window.pageYOffset > 200) {
        setScrolled("/images/madelon_logo.png");
        setColor("");
      } else {
        setScrolled("/images/Logo Off White.png");
        setColor("white");
      }
    }
  };

  useEffect(() => {
    if (path === "/redtech") {
      setScrolled("/images/Logo Marron.png");
      setColor("red");
    }
  }, [path]);

  return (
    <nav>
      {path === "/" && (
        <>
          <a href="/" className="logo-container">
            <img width="250px" src={scrolled} alt="Madelon" />
          </a>
          <div
            className={"main-menu-container " + toggle + " " + menuColor}
            onClick={toggleMenu}
          >
            <div className={"toggle " + toggle}>Menu</div>
            <ul className="main-menu">
              <li>
                <a href="/">Home</a>
              </li>
              <li>
                <a href="/about">About</a>
              </li>
              <li>
                <a href="/mission">Mission</a>
              </li>
              <li>
                <a href="/redtech">REDtech</a>
              </li>
              {/* 
              <li>
                <a href="/how-it-works">How It Works</a>
              </li>
              <li>
                <a href="/developers">Developers</a>
              </li>
              <li>
                <a href="/buildings">Buildings</a>
              </li>
              */}
              <li>
                <a href="/contact">Contact</a>
              </li>
            </ul>
          </div>
        </>
      )}
      {path === "/about" && (
        <>
          <a href="/" className="logo-container">
            <img width="250px" src={scrolled} alt="Madelon" />
          </a>
          <div
            className={"main-menu-container " + toggle + " " + menuColor}
            onClick={toggleMenu}
          >
            <div className={"toggle " + toggle}>Menu</div>
            <ul className="main-menu">
              <li>
                <a href="/">Home</a>
              </li>
              <li>
                <a href="/about">About</a>
              </li>
              <li>
                <a href="/mission">Mission</a>
              </li>
              <li>
                <a href="/redtech">REDtech</a>
              </li>
              {/* <li>
                  <a href="/how-it-works">How It Works</a>
                </li> */}
              {/*
              <li>
                <a href="/developers">Developers</a>
              </li>
              */}
              {/*
              <li>
                <a href="/buildings">Buildings</a>
              </li>
              */}
              <li>
                <a href="/contact">Contact</a>
              </li>
            </ul>
          </div>
        </>
      )}
      {path === "/redtech" && (
        <>
          <a href="/" className="logo-container">
            <img width="250px" src={scrolled} alt="Madelon" />
          </a>
          <div
            className={"main-menu-container " + toggle + " " + menuColor}
            onClick={toggleMenu}
          >
            <div className={"toggle " + toggle}>Menu</div>
            <ul className="main-menu">
              <li>
                <a href="/">Home</a>
              </li>
              <li>
                <a href="/about">About</a>
              </li>
              <li>
                <a href="/mission">Mission</a>
              </li>
              <li>
                <a href="/redtech">REDtech</a>
              </li>
              {/* 
              <li>
                <a href="/how-it-works">How It Works</a>
              </li>
              <li>
                <a href="/developers">Developers</a>
              </li>
              <li>
                <a href="/buildings">Buildings</a>
              </li>
              */}
              <li>
                <a href="/contact">Contact</a>
              </li>
            </ul>
          </div>
        </>
      )}
      {path !== "/" && path !== "/about" && path !== "/redtech" && (
        <>
          <a href="/" className="logo-container">
            <img width="250px" src="/images/madelon_logo.png" alt="Madelon" />
          </a>
          <div className={"main-menu-container " + toggle} onClick={toggleMenu}>
            <div className={"toggle " + toggle}>Menu</div>
            <ul className="main-menu">
              <li>
                <a href="/">Home</a>
              </li>
              <li>
                <a href="/about">About</a>
              </li>
              <li>
                <a href="/mission">Mission</a>
              </li>
              <li>
                <a href="/redtech">REDtech</a>
              </li>
              {/* 
              <li>
                <a href="/how-it-works">How It Works</a>
              </li>
              <li>
                <a href="/developers">Developers</a>
              </li>
              <li>
                <a href="/buildings">Buildings</a>
              </li>
              */}
              <li>
                <a href="/contact">Contact</a>
              </li>
            </ul>
          </div>
        </>
      )}
    </nav>
  );
}

export default Heading;
