import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import TagManager from 'react-gtm-module'
 
const tagManagerArgs = {
    gtmId: 'GTM-KHB3LG2'
}
 
TagManager.initialize(tagManagerArgs)

ReactDOM.render(<App />,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals