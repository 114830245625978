import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./pages/home";
import "./main.css";
import Heading from "./components/heading";
import About from "./pages/about";
import Contact from "./pages/contact";
import HowItWorks from "./pages/how-it-works";
import Odu from "./pages/odu";
import Buildings from "./pages/buildings";
import Developers from "./pages/developers";
import PressKit from "./pages/presskit";
import Redtech from "./pages/redtech";
import Mission from "./pages/mission";

function App() {
  window.Intercom("boot", {
    api_base: "https://api-iam.intercom.io",
    app_id: "uznikqm7",
  });

  return (
    <>
      <Router>
        <Heading />
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/about" exact component={About} />
          <Route path="/contact" exact component={Contact} />
          <Route path="/redtech" exact component={Redtech} />
          <Route path="/how-it-works" exact component={HowItWorks} />
          <Route path="/our-odu" exact component={Odu} />
          <Route path="/buildings" exact component={Buildings} />
          <Route path="/projects" exact component={Buildings} />
          <Route path="/developers" exact component={Developers} />
          <Route path="/presskit" exact component={PressKit} />
          <Route path="/mission" exact component={Mission} />
        </Switch>
      </Router>
    </>
  );
}

export default App;
