import React from "react";
import "./team.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";

function Team() {
  if (window.innerWidth > 768) {
    return (
      <>
        <div className="team-grid">
          <div className="team-card">
            <div className="bio">
              <img src="/images/team/alfonso.jpg" alt="Alfonso Medina" />
              <div className="bio-text">
                Development{" "}
                <a href="https://www.linkedin.com/in/alfonso-medina-459594185/">
                  <img
                    className="linkedin-icon"
                    alt=""
                    src="/images/linkedin.png"
                  />
                </a>
              </div>
            </div>
            <h5>Alfonso Medina</h5>
            <p>Co-Founder &amp; Chief Executive Officer</p>
          </div>
          <div className="team-card">
            <div className="bio">
              <img src="/images/team/joseph.jpg" alt="Joseph Ruiz" />
              <div className="bio-text">
                Architecture{" "}
                <a href="https://www.linkedin.com/in/joseph-ruiz-tapia-40892a63/">
                  <img
                    className="linkedin-icon"
                    alt=""
                    src="/images/linkedin.png"
                  />
                </a>
              </div>
            </div>
            <h5>Joseph Ruiz</h5>
            <p>Co-Founder &amp; Chief Director of the Built Environment</p>
          </div>
          <div className="team-card">
            <div className="bio">
              <img src="/images/team/dane.jpg" alt="Dane Andrews" />
              <div className="bio-text">
                Operations{" "}
                <a href="https://www.linkedin.com/in/dane-andrews-53703443/">
                  <img
                    className="linkedin-icon"
                    alt=""
                    src="/images/linkedin.png"
                  />
                </a>
              </div>
            </div>
            <h5>Dane Andrews</h5>
            <p>Co-Founder &amp; Chief Operating Officer</p>
          </div>
          <div className="team-card">
            <div className="bio">
              <img src="/images/team/sean.jpg" alt="Sean Carroll" />
              <div className="bio-text">
                Technology{" "}
                <a href="https://www.linkedin.com/in/seacar/">
                  <img
                    className="linkedin-icon"
                    alt=""
                    src="/images/linkedin.png"
                  />
                </a>
              </div>
            </div>
            <h5>Sean Carroll</h5>
            <p>
              Chief Technology
              <br />
              Officer
            </p>
          </div>
          <div className="team-card">
            <div className="bio">
              <img src="/images/team/camille.jpg" alt="Camille Faury-Donnet" />
              <div className="bio-text">
                Design{" "}
                <a href="https://www.linkedin.com/in/camille-faury-donnet-34b37561/">
                  <img
                    className="linkedin-icon"
                    alt=""
                    src="/images/linkedin.png"
                  />
                </a>
              </div>
            </div>
            <h5>Camille Faury-Donnet</h5>
            <p>Creative Director</p>
          </div>
          <div className="team-card">
            <div className="bio">
              <img src="" alt="Eduardo Feuchter" />
              <div className="bio-text">
                Product Management{" "}
                <a href="https://www.linkedin.com/in/seacar/">
                  <img
                    className="linkedin-icon"
                    alt=""
                    src="/images/linkedin.png"
                  />
                </a>
              </div>
            </div>
            <h5>Eduardo Feuchter</h5>
            <p>Head of Product</p>
          </div>
        </div>
        <h6>Advisors</h6>
        <div className="team-grid">
          <div className="team-card">
            <h5>Zach Adler</h5>
            <p>Advisor on Real Estate Development</p>
            <a href="https://www.linkedin.com/in/zachadler/">
              <FontAwesomeIcon icon={faLinkedin} />
            </a>
          </div>
          <div className="team-card">
            <h5>Ryan Pfeiffere</h5>
            <p>Advisor on Capital Investment and Finance</p>
            <a href="https://www.linkedin.com/in/ryanpfeiffer/">
              <FontAwesomeIcon icon={faLinkedin} />
            </a>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <div className="team-slider">
        <div className="team-slider-inner">
          {/*<div className="team-slide">
                        <div className="bio">
                            <img src="/images/alfonso.jpg" alt="Alfonso Medina"/>
                            <div className="bio-text">Development <a href="https://www.linkedin.com/in/alfonso-medina-459594185/"><img className="linkedin-icon" alt="" src="/images/linkedin.png"/></a></div>
                        </div>
                        <h5>Alfonso<br/>Medina</h5>
                        <p>Co-Founder and<br/>Chief Executive Officer</p>
                    </div>
                    <div className="team-slide">
                        <div className="bio">
                            <img src="/images/joseph.jpg" alt="Joseph Ruiz"/>
                            <div className="bio-text">Architecture <a href="https://www.linkedin.com/in/joseph-ruiz-tapia-40892a63/"><img className="linkedin-icon" alt="" src="/images/linkedin.png"/></a></div>
                        </div>
                        <h5>Joseph<br/>Ruiz</h5>
                        <p>Co-Founder and<br/>Chief Creative Officer</p>
                    </div>
                    <div className="team-slide">
                        <div className="bio">
                            <img src="/images/dane.jpg" alt="Dane Andrews"/>
                            <div className="bio-text">Operations <a href="https://www.linkedin.com/in/dane-andrews-53703443/"><img className="linkedin-icon" alt="" src="/images/linkedin.png"/></a></div>
                        </div>
                        <h5>Dane<br/>Andrews</h5>
                        <p>Co-Founder and<br/>Chief Operating Officer</p>
                    </div>
                    <div className="team-slide">
                        <div className="bio">
                            <img src="/images/camille.jpg" alt="Camille Faury-Donnet"/>
                            <div className="bio-text">Design <a href="https://www.linkedin.com/in/camille-faury-donnet-34b37561/"><img className="linkedin-icon" alt="" src="/images/linkedin.png"/></a></div>
                        </div>
                        <h5>Camille<br/>Faury-Donnet</h5>
                        <p>Chief Brand Officer</p>
                    </div>
                    <div className="team-slide">
                        <div className="bio">
                            <img src="/images/sean.jpg" alt="Sean Caroll"/>
                            <div className="bio-text">Technology <a href="https://www.linkedin.com/in/seacar/"><img className="linkedin-icon" alt="" src="/images/linkedin.png"/></a></div>
                        </div>
                        <h5>Sean<br/>Carroll</h5>
                        <p>Chief Technology<br/>Officer</p>
                    </div>
                    <div className="team-slide">
                        <div className="bio">
                            <img src="/images/Artboard_17.png" alt="Ryan Pfeiffere"/>
                            <div className="bio-text">Finance <a href="https://www.linkedin.com/in/ryanpfeiffer/"><img className="linkedin-icon" alt="" src="/images/linkedin.png"/></a></div>
                        </div>
                        <h5>Ryan<br/>Pfeiffere</h5>
                        <p>Equity Advisor<br/>& Fundraising</p>
                    </div>*/}
          <div className="team-slide">
            <div className="bio">
              <img src="/images/team/alfonso.jpg" alt="Alfonso Medina" />
              <div className="bio-text">
                Development{" "}
                <a href="https://www.linkedin.com/in/alfonso-medina-459594185/">
                  <img
                    className="linkedin-icon"
                    alt=""
                    src="/images/linkedin.png"
                  />
                </a>
              </div>
            </div>
            <h5>Alfonso Medina</h5>
            <h5>Co-Founder &amp; Chief Executive Officer</h5>
          </div>
          <div className="team-slide">
            <div className="bio">
              <img src="/images/team/joseph.jpg" alt="Joseph Ruiz" />
              <div className="bio-text">
                Architecture{" "}
                <a href="https://www.linkedin.com/in/joseph-ruiz-tapia-40892a63/">
                  <img
                    className="linkedin-icon"
                    alt=""
                    src="/images/linkedin.png"
                  />
                </a>
              </div>
            </div>
            <h5>Joseph Ruiz</h5>
            <p>Co-Founder &amp; Chief Director of the Built Environment</p>
          </div>
          <div className="team-slide">
            <div className="bio">
              <img src="/images/team/dane.jpg" alt="Dane Andrews" />
              <div className="bio-text">
                Operations{" "}
                <a href="https://www.linkedin.com/in/dane-andrews-53703443/">
                  <img
                    className="linkedin-icon"
                    alt=""
                    src="/images/linkedin.png"
                  />
                </a>
              </div>
            </div>
            <h5>Dane Andrews</h5>
            <p>Co-Founder &amp; Chief Operating Officer</p>
          </div>
          <div className="team-slide">
            <div className="bio">
              <img src="/images/team/camille.jpg" alt="Camille Faury-Donnet" />
              <div className="bio-text">
                Design{" "}
                <a href="https://www.linkedin.com/in/camille-faury-donnet-34b37561/">
                  <img
                    className="linkedin-icon"
                    alt=""
                    src="/images/linkedin.png"
                  />
                </a>
              </div>
            </div>
            <h5>Camille Faury-Donnet</h5>
            <p>Creative Director</p>
          </div>
          <div className="team-slide">
            <div className="bio">
              <img src="/images/team/sean.jpg" alt="Sean Caroll" />
              <div className="bio-text">
                Technology{" "}
                <a href="https://www.linkedin.com/in/seacar/">
                  <img
                    className="linkedin-icon"
                    alt=""
                    src="/images/linkedin.png"
                  />
                </a>
              </div>
            </div>
            <h5>Sean Caroll</h5>
            <p>
              Chief Technology
              <br />
              Officer
            </p>
          </div>
          <div className="team-slide">
            <h5>Zach Adler</h5>
            <p>Advisor on Real Estate Development</p>
          </div>
          <div className="team-slide">
            <h5>Ryan Pfeiffere</h5>
            <p>Advisor on Capital Investment and Finance</p>
          </div>
        </div>
      </div>
    );
  }
}

export default Team;
