import React from "react";
import "./footer.css";

function Footerlink(props) {
  return (
    <li>
      <a href={props.to} className={props.class}>
        {props.name}
      </a>
    </li>
  );
}
function Footer(props) {
  const active = props.active;
  return (
    <footer className={active}>
      <div className="footer-menu-container">
        <ul className="footer-menu">
          <Footerlink
            to="/"
            name="Home"
            class={active === "0" ? "active" : ""}
          />
          <Footerlink
            to="/about"
            name="About"
            class={active === "1" ? "active" : ""}
          />
          <Footerlink
            to="/mission"
            name="Mission"
            class={active === "10" ? "active" : ""}
          />

          <Footerlink
            to="/redtech"
            name="REDtech"
            class={active === "3" ? "active" : ""}
          />
          {/*<Footerlink to="/how-it-works" name="How It Works" class={active === '2' ? 'active' : ''} />*/}
          {/*
          <Footerlink
            to="/developers"
            name="Developers"
            class={active === "3" ? "active" : ""}
          />
  */}
          {/*<Footerlink to="/buildings" name="Buildings" class={active === '5' ? 'active' : ''} />*/}
          <Footerlink
            to="/contact"
            name="Contact"
            class={active === "6" ? "active" : ""}
          />
        </ul>
      </div>
      <div className="legalish-stuff">
        <div className="copyright">2019 - 2023 &#169; Madelon</div>
        <div className="logo">
          <img width="200px" src="/images/logo-blanco.svg" alt="Madelon" />
        </div>
      </div>
      <div className="icons">
        <a href="https://twitter.com/madelongroup?lang=en" className="icon">
          <img width="50px" src="/images/twitter-icon.png" alt="Twitter" />
        </a>
        <a href="https://madelongroup.medium.com/" className="icon">
          <img width="50px" src="/images/medium-icon.png" alt="Medium" />
        </a>
        <a
          href="https://www.linkedin.com/company/madelon-group/"
          className="icon"
        >
          <img width="50px" src="/images/linkedin.png" alt="Linkedin" />
        </a>
      </div>
    </footer>
  );
}

export default Footer;
