import React, { Component } from 'react';
import Footer from '../components/footer';

import { Helmet } from 'react-helmet';

class PressKit extends Component {

  render(){
    return (
        <>
            <Helmet>
                    <title>Madelon - Press Kit</title>
            </Helmet>
            <div className="row presskit-container">
                <div>
                    <img className="press-image" src="/images/presskit/MADELON-LOGO-BLUE.png" alt="logo blue"/>
                </div>
                <div>
                    <img className="press-image" src="/images/presskit/MADELON-LOGO-DARK.png" alt="logo dark"/>
                </div>
                <div>
                    <img className="press-image" src="/images/presskit/MADELON-LOGO-WHITE.png" alt="logo white"/>
                </div>
            </div>
            <Footer />
        </>
    )
  }
}

export default PressKit
