import React, { useState } from 'react'
import './slider.css'

export default function Slider(props) {

    const [current, setCurrent] = useState(0);

    const length = props.data.length;
    
    const slide = (i) => {
        setCurrent(i)
    }
    /*const slideLeft = () => {
        setCurrent(current === 0 ? length - 1 : current - 1);
    }*/

    //console.log( props.data ); 
    let bullets = [];
    for (let index = 0; index < length; index++) {
        if( index === current){
            bullets.push(<div className="bullet active" onClick={ ()=>{slide(index)} }></div>)
        }
        else{
            bullets.push(<div className="bullet" onClick={ ()=>{slide(index)} }></div>)
        }
    }
    return (
        <div className="slider-wrapper">
            <div className="slider">
                {props.data.map( (slide, index) =>{
                    if( current === index ){
                        return <div className="slide active" style={ { backgroundImage: 'url(' + slide.image + ')' } }></div>
                    }
                    else{
                        return <div className="slide" style={ { backgroundImage: 'url(' + slide.image + ')' } }></div>
                    }
                } )}
            </div>
            <div className="bullets">
                { bullets }
            </div>
        </div>
    )
}


