import React from 'react';
import './bannerCTA.css';

function BannerCTA() {

    return (
        <div className="banner-cta">
            <a href="https://redtech.app"><p>Try the REDtech platform</p></a>
        </div>
    )
}

export default BannerCTA
