import React from "react";
import Footer from "../components/footer";
import BannerCTA from "../components/bannerCTA";

import { Helmet } from "react-helmet";
import SlideIn from "../components/SlideIn";
import FadeIn from "../components/FadeIn";

function Mission() {
  return (
    <>
      <Helmet>
        <title>Madelon - Mission</title>
      </Helmet>
      <div
        className="secondary-page-about offWhite"
        style={{ position: "relative" }}
      >
        <div className="row">
          <SlideIn>
            <h1 style={{ margin: 0, padding: 0, textAlign: "center" }}>
              A Mission Driven Company
            </h1>
          </SlideIn>
          <SlideIn>
            <p style={{ textAlign: "center", margin: "25px auto" }}>
              We see housing as a fundamental human right that can empower
              people and communities achieve great things and we believe this
              does not need to happen at the expense of the planet. That is why
              we catalyze the creation of housing that is…
            </p>
          </SlideIn>

          <SlideIn>
            {window.innerWidth < 768 && (
              <img
                style={{ width: "100%", padding: "55px 0" }}
                src={"/images/GM1.png"}
                alt="graphic"
              />
            )}
            {window.innerWidth >= 768 && (
              <img
                style={{ width: "100%", padding: "75px 0" }}
                src={"/images/G3.png"}
                alt="graphic"
              />
            )}
          </SlideIn>
        </div>
        <div className="row">
          <SlideIn>
            <h1>Housing that is Affordable</h1>
            <p style={{ maxWidth: "100%", width: "100%" }}>
              Having a place to live is one of the most basic human needs; We
              believe that in order to face all the challenges of daily life
              having a roof over your head is imperative and access to this has
              become increasingly unaffordable. Madelon’s mission is to
              accelerate the production of housing in cities to make them
              affordable places to live.
            </p>
            <FadeIn>
              {window.innerWidth < 768 && (
                <img
                  style={{ width: "100%", padding: "55px 0" }}
                  src={"/images/AM1.png"}
                  alt="graphic"
                />
              )}
              {window.innerWidth >= 768 && (
                <img
                  style={{ width: "100%", padding: "75px 0" }}
                  src={"/images/A1.png"}
                  alt="graphic"
                />
              )}
            </FadeIn>
            <p style={{ maxWidth: "100%", width: "100%" }}>
              The dramatic rent and sales price increase of housing over the
              past years due to the lack of supply has led major metropolitan
              areas in the US to a critical point of inflection. A moment where
              significant structural changes need to happen to correct course
              and make cities affordable.
            </p>
            <FadeIn>
              {window.innerWidth < 768 && (
                <img
                  style={{ width: "100%", padding: "55px 0" }}
                  src={"/images/AM2.png"}
                  alt="graphic"
                />
              )}
              {window.innerWidth >= 768 && (
                <img
                  style={{ width: "100%", padding: "75px 0" }}
                  src={"/images/A2.png"}
                  alt="graphic"
                />
              )}
            </FadeIn>
            <p style={{ maxWidth: "100%", width: "100%" }}>
              A big misalignment of incentives in the process of delivering
              housing in cities has led to an unsustainable pace of production,
              driving prices up and leaving a lot of people without a place to
              live.
            </p>
          </SlideIn>
        </div>
        <div className="row">
          <SlideIn>
            <h1>Housing that is Sustainable</h1>
            <p style={{ maxWidth: "100%", width: "100%" }}>
              We believe in good housing delivery practices that are
              environmentally friendly and climate-resilient. Madelon
              facilitates the use of industrialized construction methods though
              a productized approach to establish the framework for
              carbon-reduction goals across the entire building ecosystem.
            </p>
            <p
              style={{
                color: "#4BBC7E",
                fontSize: "1.5em",
                fontFamily: 'Roboto Condensed", sans-serif',
                fontWeight: "900",
              }}
            >
              The Construction industry is responsible for 40% of global energy
              consumption and 33% of greenhouse gas emissions.
            </p>
            <FadeIn>
              {window.innerWidth < 768 && (
                <img
                  style={{ width: "100%", padding: "55px 0" }}
                  src={"/images/GM4.png"}
                  alt="graphic"
                />
              )}
              {window.innerWidth >= 768 && (
                <img
                  style={{ width: "100%", padding: "75px 0" }}
                  src={"/images/G4.png"}
                  alt="graphic"
                />
              )}
            </FadeIn>
            <FadeIn>
              {window.innerWidth < 768 && (
                <img
                  style={{ width: "100%", padding: "55px 0" }}
                  src={"/images/GM5.png"}
                  alt="graphic"
                />
              )}
              {window.innerWidth >= 768 && (
                <img
                  style={{ width: "100%", padding: "75px 0" }}
                  src={"/images/G5.png"}
                  alt="graphic"
                />
              )}
            </FadeIn>
            <FadeIn>
              <p
                style={{
                  fontSize: "1.5em",
                  fontFamily: 'Roboto Condensed", sans-serif',
                  fontWeight: "900",
                  color: "#4BBC7E",
                }}
              >
                Other building technologies like CLT building have the capacity
                to capture carbon throughout their lifespan, reducing the global
                warming potential by
              </p>
              {window.innerWidth < 768 && (
                <img
                  style={{ width: "100%", padding: "55px 0" }}
                  src={"/images/GM6.png"}
                  alt="graphic"
                />
              )}
              {window.innerWidth >= 768 && (
                <img
                  style={{ width: "100%", padding: "75px 0" }}
                  src={"/images/G6.png"}
                  alt="graphic"
                />
              )}
            </FadeIn>
          </SlideIn>
        </div>
        <div className="row">
          <SlideIn>
            <h1>Housing that is Empowering</h1>
            <p style={{ maxWidth: "100%", width: "100%" }}>
              We believe in human-centered design as a tool to have a great
              impact not only on the lives of individuals but society at large.
              We believe that access to housing is at the foundation of a
              functional society. Moreover, we believe that the places we live
              in should empower and inspire us to do great things for ourselves
              and the cities and communities we are a part of. We are convinced
              that design has the capacity to achieve this. Madelon utilizes
              design as a tool for inclusiveness. A tool to make people’s lives
              better and enable communities to flourish.
            </p>
          </SlideIn>
        </div>
      </div>
      <Footer active="10" />
      <BannerCTA />
    </>
  );
}

export default Mission;
