import React from "react";
import Footer from "../components/footer";
import BannerCTA from "../components/bannerCTA";

import { Helmet } from "react-helmet";
import SlideIn from "../components/SlideIn";
import FadeIn from "../components/FadeIn";

function REDtech() {
  return (
    <>
      <Helmet>
        <title>Madelon - About</title>
      </Helmet>
      <div
        className="secondary-page-about offWhite"
        style={{ position: "relative" }}
      >
        <div className="row">
          <SlideIn>
            <h1>The REDtech Platform</h1>
            <p>
              The Madelon REDtech platform is a suite of tools that help you to
              develop faster, more efficiently, and with less risk.
            </p>
          </SlideIn>
        </div>
        <div className="row">
          <div
            style={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              alignItems: "stretch",
              justifyContent: "center",
              gap: "35px",
            }}
          >
            <div
              style={{
                padding: "25px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "center",
                textAlign: "center",
                flexBasis: "30%",
              }}
            >
              <img
                style={{ maxWidth: "100%", maxHeight: "200px" }}
                src={"/images/identify.png"}
                alt="Identify"
              />
              <h1 style={{ padding: 0, margin: "20px 0 0 0" }}>Search</h1>
              <p style={{ fontSize: "0.8em" }}>
                Search, filter, and compare thousands of properties to find the
                right one for your development thesis.
              </p>
            </div>
            <div
              style={{
                padding: "25px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "center",
                textAlign: "center",
                flexBasis: "30%",
              }}
            >
              <img
                style={{ maxWidth: "100%", maxHeight: "200px" }}
                src={"/images/underwrite.png"}
                alt="Underwrite"
              />
              <h1 style={{ padding: 0, margin: "20px 0 0 0" }}>Underwrite</h1>
              <p style={{ fontSize: "0.8em" }}>
                Analyze the architectural and financial feasibility of your
                project and determine the optimal development strategy.
              </p>
            </div>
            <div
              style={{
                padding: "25px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "center",
                textAlign: "center",
                flexBasis: "30%",
              }}
            >
              <img
                style={{ maxWidth: "100%", maxHeight: "200px" }}
                src={"/images/build.png"}
                alt="Build"
              />
              <h1 style={{ padding: 0, margin: "20px 0 0 0" }}>Connect</h1>
              <p style={{ fontSize: "0.8em" }}>
                Connect with our pre-vetted partner network and work directly
                with the Madelon team throughout the entire development process.
              </p>
            </div>
          </div>
        </div>
        <div
          className="row"
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <SlideIn style={{ flexBasis: "40%" }}>
            <h2 className="">Get started for free</h2>
            <p>
              Search millions of parcels to find the one that fits your
              development thesis.
            </p>
            <button
              style={{
                fontSize: "0.6em",
                fontWeight: "300",
                backgroundColor: "#eb4e32",
                padding: "15px 25px",
                color: "#fff",
                border: "1px solid #eb4e32",
                borderRadius: "15px",
              }}
            >
              <a href="https://redtech.app" style={{ color: "#fff" }}>
                Learn More
              </a>
            </button>
          </SlideIn>
          <FadeIn>
            <img
              style={{ width: "100%" }}
              src={"/images/iphone.png"}
              alt="REDtech"
            />
          </FadeIn>
        </div>
      </div>
      <Footer active="3" />
      <BannerCTA />
    </>
  );
}

export default REDtech;
