import React, { useState /*useEffect*/ } from "react";
import Footer from "../components/footer";
import Team from "../components/team.js";
//import HeroBanner from "../components/heroBanner";
import BannerCTA from "../components/bannerCTA";

import { Helmet } from "react-helmet";
import SlideIn from "../components/SlideIn";
import FadeIn from "../components/FadeIn";

//<HeroBanner text="Our Mission is to Democratize Housing" position="bottom" background="Artboard_10.png"/>

function About() {
  /*const [hero, setHero] = useState('video');

    useEffect(() => {
        if(window.innerWidth > 990){
            setHero('video');
        }else{
            setHero('image');
        }   
    }, []);


    window.onresize = function(){
     if(window.innerWidth > 990){
         setHero('video');
     }else{
         setHero('image');
     }
    }*/

  const [icon, setIcon] = useState(
    "http://image.flaticon.com/icons/svg/10/10776.svg"
  );
  const [mute, setMute] = useState(true);

  const toggleMute = () => {
    if (icon === "http://image.flaticon.com/icons/svg/10/10430.svg") {
      setIcon("http://image.flaticon.com/icons/svg/10/10776.svg");
      setMute(true);
    } else {
      setIcon("http://image.flaticon.com/icons/svg/10/10430.svg");
      setMute(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>Madelon - About</title>
      </Helmet>

      {/*hero === 'image' && <HeroBanner background="/images/city.jpg"/>*/}
      {
        /*hero === 'video' &&*/ <div style={{ position: "sticky", top: 0 }}>
          <img
            className="mute-icon"
            onClick={toggleMute}
            src={icon}
            alt="mute icon"
          />
          <video autoPlay muted={mute} loop playsInline>
            <source
              src="https://redtech.app/videos/about.mp4"
              type="video/mp4"
            />
          </video>
        </div>
      }
      <div
        className="secondary-page-about offWhite"
        style={{ position: "relative" }}
      >
        <div className="row">
          {/* <div className="image-background-wrapper"><img className="image-background" src="/images/Artboard_11.png"/></div> */}
          <div className="image-background-content">
            <SlideIn>
              <h2 className="">
                Founded by a Developer, an Architect, and an Operator
              </h2>
            </SlideIn>
          </div>
          <SlideIn>
            <p className="max-w-60">
              Madelon believes in housing not just as a human right, but as a
              tool, a tool to make people's lives better. Therefore, we created
              a completely new development model that is informed by technology
              and the ever-changing financial and social needs of individuals.
            </p>
          </SlideIn>
        </div>
        <div className="row-image">
          <FadeIn>
            <Team />
          </FadeIn>
        </div>
      </div>
      <Footer active="1" />
      <BannerCTA />
    </>
  );
}

export default About;
