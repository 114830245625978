import React from 'react';
//import HeroBanner from '../components/heroBanner';
import Footer from '../components/footer';
import FadeIn from '../components/FadeIn'

import { Helmet } from 'react-helmet';
import SlideIn from '../components/SlideIn';

class Odu extends React.Component {
  
  constructor(props){
    super(props)
    this.state = {
      doAnimation: ''
    }
    this.handleLoad = this.handleLoad.bind(this)
  }

  componentDidMount(){    
    window.addEventListener('load', this.handleLoad)
  }
  
  componentWillUnmount(){
    window.removeEventListener('load', this.handleLoad)
  }
  
  handleLoad(){
    this.setState({doAnimation: <>
    <div className="row center-text pb0">
              <SlideIn>
                <h2 className="upper">The Optimized dwelling unit</h2>
              </SlideIn>
              <SlideIn>
                <h5>Madelon's ODU</h5>
              </SlideIn>
              <SlideIn>
                <p>As a response to this seemingly perpetual housing crisis, we have created the Optimized Dwelling Unit, an adaptable code-compliant modular unit that uses highly efficient spatial design and occupancy parameters to deliver the dwelling unit of the cities of the future. The modular nature of our ODUs facilitates a streamlined production of housing units where they are needed most while providing space for flexibility and adaptability to the environmental, financial, social, and zoning particularities of any city.</p>
              </SlideIn>
            </div>
            <div className="image-wrapper">
              <FadeIn>
                <img className="odu-image" src="/images/ODUs.jpg" alt="Optimized Dwelling Unit"/>
              </FadeIn>
            </div></>})
  }

  render(){
    return (
        <>
          <Helmet>
                <title>Madelon - ODU</title>
          </Helmet>
          { /*<HeroBanner text="Optimized Dwelling Unit" position="bottom" background="/images/Artboard_18.png"/>*/ }
          <div className="image-wrapper">
            <FadeIn>
              <img className="image-cover" src="/images/ODU Color.png" alt="odu cover"/>
            </FadeIn>
          </div>
          <div className="">
            { this.state.doAnimation }
          </div>
            <Footer active="3"/>
        </>
    )
  }
}

export default Odu
