import React from "react";
//import HeroBanner from '../components/heroBanner';
import Footer from "../components/footer";
import SlideIn from "../components/SlideIn";
import FadeIn from "../components/FadeIn";

import { Helmet } from "react-helmet";

class HowItWorks extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      doAnimation: "",
    };
    this.handleLoad = this.handleLoad.bind(this);
  }
  componentDidMount() {
    window.addEventListener("load", this.handleLoad);
  }
  componentWillUnmount() {
    window.removeEventListener("load", this.handleLoad);
  }
  handleLoad() {
    this.setState({
      doAnimation: (
        <>
          <div className="row-hiw center-text pb0">
            <SlideIn wait={this.state.doAnimation}>
              <h5>Taking you from Land to Cash Flow</h5>
            </SlideIn>
            <SlideIn>
              <p>
                Stop reinventing the wheel for every project. Madelon has
                productized development, meaning we balanced customization and
                standardization to make ground up residential projects more
                repeatable therefore reducing risk and shortening timelines.
              </p>
            </SlideIn>
          </div>
          <div className="row-hiw flex align-end">
            <div className="card-half-hiw">
              <SlideIn>
                <div className="card-number">1.</div>
                <h3 className="hiw-title">
                  Analyze Parcels on our REDtech Platform
                </h3>
                <p className="mt0">
                  The REDtech platform makes it easy for you to quickly search
                  lots by address and projected purchase price to assess project
                  viability.
                </p>
                <p className="mt0">
                  <a className="btn" href="https://redtech.app">
                    Start Analyzing Now for Free
                  </a>
                </p>
              </SlideIn>
            </div>
            <div className="card-half-hiw">
              <FadeIn>
                <img
                  className="card-full-hiw"
                  src="/images/HIW Drawings(2)-1.png"
                  alt=""
                />
              </FadeIn>
            </div>
          </div>
          <div className="row-hiw flex align-end reverse-columns">
            <div className="card-half-hiw">
              <FadeIn>
                <img
                  className="card-full-hiw"
                  src="/images/HIW Drawings(2)-2.png"
                  alt=""
                />
              </FadeIn>
            </div>
            <div className="card-half-hiw">
              <SlideIn>
                <div className="card-number">2.</div>
                <h3 className="hiw-title">
                  Get an Automatically Generated Floorplan
                </h3>
                <p className="mt0">
                  The REDtech platform will analyze the physical dimensions of
                  the lot and the local zoning code to provide an
                  algorithmically generated code compliant floorplan that
                  maximizes NOI. Easily compare our ODU model to traditional
                  multifamily on the same lot directly with the platform.
                </p>
              </SlideIn>
            </div>
          </div>
          <div className="row-hiw flex align-end">
            <div className="card-half-hiw">
              <SlideIn>
                <div className="card-number">3.</div>
                <h3 className="hiw-title">
                  View Offer Memorandum and Proforma
                </h3>
                <p className="mt0">
                  The REDtech platform takes the generated floorplan, projected
                  development costs, area demographics, and rent comps for the
                  market to determine overall project viability. All are then
                  summarized in a downloadable Offer Memorandum and Pro-Forma.
                </p>
              </SlideIn>
              {/*<p className="mt0"><a class="btn" href="https://beta.madredtech.com">Get Started!</a></p>*/}
            </div>
            <div className="card-half-hiw">
              <FadeIn>
                <img
                  className="card-full-hiw"
                  src="/images/HIW Drawings(2)-3.png"
                  alt=""
                />
              </FadeIn>
            </div>
          </div>
          <div className="row-hiw flex align-end reverse-columns">
            <div className="card-half-hiw">
              <FadeIn>
                <img
                  className="card-full-hiw"
                  src="/images/HIW Drawings(2)-4.png"
                  alt=""
                />
              </FadeIn>
            </div>
            <div className="card-half-hiw">
              <SlideIn>
                <div className="card-number">4.</div>
                <h3 className="hiw-title">A Final Project Review</h3>
                <p className="mt0">
                  When you are ready to move forward with a project, this is
                  where Madelon becomes your best partner. From the REDtech
                  platform, we review the project specifications and help you
                  choose the optimal GC and pre-fab manufacturer from our short
                  list of pre-vetted partners.
                </p>
              </SlideIn>
            </div>
          </div>
          <div className="row-hiw flex align-end">
            <div className="card-half-hiw">
              <SlideIn>
                <div className="card-number">5.</div>
                <h3 className="hiw-title">Permitting and Factory Setup</h3>
                <p className="mt0">
                  Once the best factory partner is identified, we will leverage
                  our pre-established program to streamline the process;
                  massively lowering setup costs and shortening design
                  timelines.
                </p>
              </SlideIn>
            </div>
            <div className="card-half-hiw">
              <FadeIn>
                <img className="card-full-hiw" src="/images/HIW5.png" alt="" />
              </FadeIn>
            </div>
          </div>
          <div className="row-hiw flex align-end reverse-columns">
            <div className="card-half-hiw">
              <FadeIn>
                <img className="card-full-hiw" src="/images/HIW6.png" alt="" />
              </FadeIn>
            </div>
            <div className="card-half-hiw">
              <SlideIn>
                <div className="card-number">6.</div>
                <h3 className="hiw-title">Break Ground</h3>
                <p className="mt0">
                  We are your partner every step of the way through the actual
                  construction of the project to ensure everything runs
                  smoothly. All of our onsite and offsite partners are experts
                  in what they do, meaning you can rest assured the project will
                  be delivered on time and on budget.
                </p>
              </SlideIn>
            </div>
          </div>
          <div className="row-hiw flex align-end">
            <div className="card-half-hiw">
              <SlideIn>
                <div className="card-number">7.</div>
                <h3 className="hiw-title">
                  Leverage Our Existing Property Management Partners
                </h3>
                <p className="mt0">
                  Select one of our national property management partners to
                  lease-up and then run the day to day operations of the
                  building once completed. Madelon completes quarterly audits to
                  ensure operational quality standards.
                </p>
              </SlideIn>
            </div>
            <div className="card-half-hiw">
              <FadeIn>
                <img
                  className="card-full-hiw"
                  src="/images/HIW Drawings(2)-7.png"
                  alt=""
                />
              </FadeIn>
            </div>
          </div>
          <div className="row-hiw flex align-end reverse-columns">
            <div className="card-half-hiw">
              <FadeIn>
                <img className="card-full-hiw" src="/images/HIW8.png" alt="" />
              </FadeIn>
            </div>
            <div className="card-half-hiw">
              <SlideIn>
                <div className="card-number">8.</div>
                <h3 className="hiw-title">Grand Opening</h3>
                <p className="mt0">
                  It's here! Your building is leased up and generating cash
                  flow.
                </p>
              </SlideIn>
            </div>
          </div>
          <div className="row-hiw center-text offWhite" id="invite">
            <SlideIn>
              <h2 className="upper">Try the redtech platform now</h2>
              <a className="btn" href="https://redtech.app">
                Demo Platform
              </a>
            </SlideIn>
          </div>
        </>
      ),
    });
  }
  render() {
    return (
      <>
        <Helmet>
          <title>Madelon - How It Works</title>
        </Helmet>
        {/*<HeroBanner text="How it Works" position="bottom" background="/images/Artboard_18.png"/>*/}
        <div className="image-wrapper">
          <img
            className="image-cover"
            src="./images/How It Works Cover.jpg"
            alt=""
          />
        </div>
        <div className="gradient-1">{this.state.doAnimation}</div>
        <Footer active="2" />
      </>
    );
  }
}

export default HowItWorks;
