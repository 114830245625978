import React, { useState } from "react";
import "./ContactForm.css";
import axios from "axios";

function ContactForm() {
  const [firstName, setFirstName] = useState("");
  const [email, setEmail] = useState("");
  const [lastName, setLastName] = useState("");
  const [message, setMessage] = useState("");
  const [submitMessage, setSubmitMessage] = useState("");

  async function submitForm(e) {
    e.preventDefault();

    const webhookUrl =
      "https://hooks.slack.com/services/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";

    const data = {
      text: `Name: ${firstName} ${lastName} \n${email} \n${message}`,
    };

    try {
      await axios.post(webhookUrl, JSON.stringify(data), {
        withCredentials: false,
        transformRequest: [
          (data, headers) => {
            delete headers.post["Content-Type"];
            return data;
          },
        ],
      });

      // Clear form fields
      setFirstName("");
      setLastName("");
      setEmail("");
      setMessage("");

      // Set success message
      setSubmitMessage("Message sent successfully!");

      // Clear success message after 3 seconds
      setTimeout(() => setSubmitMessage(""), 3000);
    } catch (error) {
      console.error("Error submitting form:", error);
      setSubmitMessage("An error occurred. Please try again.");
    }
  }

  return (
    <form
      className="contact-form"
      name="contact"
      method="POST"
      onSubmit={submitForm}
    >
      <input type="hidden" name="form-name" value="contact" />
      <p className="center-text">Get In Touch</p>
      <div className="input-row">
        <label>First Name</label>
        <input
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          type="text"
          name="firstName"
          required
        />
      </div>
      <div className="input-row">
        <label>Last Name</label>
        <input
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          type="text"
          name="lastName"
          required
        />
      </div>
      <div className="input-row">
        <label>Email Address</label>
        <input
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          type="email"
          name="email"
          required
        />
      </div>
      <div className="input-row">
        <label>Message</label>
        <textarea
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          name="message"
          rows="5"
          required
        />
      </div>
      <button type="submit">Submit</button>
      {submitMessage && <p className="submit-message">{submitMessage}</p>}
    </form>
  );
}

export default ContactForm;
