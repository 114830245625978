import React from "react";
import Footer from "../components/footer";

import SlideIn from "../components/SlideIn";

import { Helmet } from "react-helmet";
import FadeIn from "../components/FadeIn";

function Home() {
  return (
    <>
      <Helmet>
        <title>Madelon - Home</title>
      </Helmet>
      <div className="video-hero-1">
        <div className="video-hero-video">
          <video preload="metadata" playsInline loop autoPlay muted>
            <source
              src="https://redtech.app/videos/scene1.mp4"
              type="video/mp4"
            />
          </video>
        </div>
        {/*<div className="video-hero-overlay"></div>*/}
      </div>
      {/*<img src="/images/flechita.png" className="downArrow" alt=""/>*/}
      <div
        className="offWhite"
        style={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <div className="row">
          <SlideIn>
            <h1>We need More Housing</h1>
            <p>
              Madelon is catalyzing the creation of more housing that is
              affordable by streamlining the development process, and connecting
              stakeholders to industrialized housing manufacturers.
            </p>
          </SlideIn>
        </div>
        <div className="row">
          <SlideIn>
            <h1 style={{ textAlign: "center" }}>Madelon bridges the gap</h1>
            <FadeIn>
              {window.innerWidth < 768 && (
                <img
                  style={{ width: "100%", padding: "75px 0" }}
                  src={"/images/GM2.png"}
                  alt="graphic"
                />
              )}
              {window.innerWidth >= 768 && (
                <img
                  style={{ width: "100%", padding: "75px 0" }}
                  src={"/images/G1.png"}
                  alt="graphic"
                />
              )}
            </FadeIn>
            <p>
              By partnering with con-tech companies and productizing housing
              solutions that respond to different regional and market needs,
              Madelon brings new participants into the housing development
              industry.
            </p>
          </SlideIn>
        </div>
        <div className="row" style={{ backgroundColor: "#082827" }}>
          <h1 style={{ textAlign: "center", color: "#fff" }}>
            Why Industrialized Construction?
          </h1>
          <p
            style={{
              textAlign: "center",
              color: "#4BBC7E",
              fontSize: "2em",
              margin: "0 auto",
              fontFamily: 'Roboto Condensed", sans-serif',
              fontWeight: "900",
            }}
          >
            It saves time
            <br />
            It is more sustainable
            <br />
            It is the future
          </p>
        </div>
        <div className="row">
          <FadeIn>
            {window.innerWidth < 768 && (
              <img
                style={{ width: "100%", padding: "25px 0" }}
                src={"/images/GM3.png"}
                alt="graphic"
              />
            )}
            {window.innerWidth >= 768 && (
              <img
                style={{ width: "100%", padding: "75px 0" }}
                src={"/images/G2.png"}
                alt="graphic"
              />
            )}
          </FadeIn>
          <SlideIn style={{ textAlign: "center" }}>
            <p
              style={{
                textAlign: "center",
                marginTop: "100px",
                margin: "15px auto",
                width: "100%",
                maxWidth: "100%",
              }}
            >
              Advancement in building technologies can become the most important
              tool to increase the housing supply in cities. The benefits that
              come with the implementation of different industrialized
              construction systems translate directly to a more efficient
              delivery of affordable housing. Madelon puts these benefits within
              reach of new developers and stakeholders so more infill urban
              housing can be created.
            </p>
            <div style={{ textAlign: "center" }}>
              <button
                style={{
                  fontSize: ".6em",
                  fontWeight: "300",
                  backgroundColor: "#EF561A",
                  padding: "15px 25px",
                  color: "#fff",
                  border: "1px solid  #EF561A",
                }}
              >
                <a href="/redtech" style={{ color: "#fff" }}>
                  Learn More
                </a>
              </button>
            </div>
          </SlideIn>
        </div>
        {window.innerWidth < 768 && (
          <div className="row">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                minHeight: { xs: "none", md: "100vh" },
                width: "100%",
                scrollSnapAlign: "start",
                textAlign: "center",
                padding: "25px",
                marginTop: { xs: "50px", md: "0px" },
                marginBottom: { xs: "50px", md: "0px" },
              }}
            >
              <h1 style={{ margin: "50px 0", padding: 0 }}>
                The Madelon Process
              </h1>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "stretch",
                  justifyContent: "center",
                  gap: "35px",
                }}
              >
                <div
                  style={{
                    padding: "25px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <img
                    style={{ height: "250px" }}
                    src={"/images/first.png"}
                    alt="Underwrite"
                  />
                  <p style={{ width: "100%", padding: "15px" }}>
                    Madelon provides an informed insight into a development
                    opportunity using one of its prepackaged housing solutions.
                  </p>
                </div>
                <div
                  style={{
                    padding: "25px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <img
                    style={{
                      height: "250px",
                    }}
                    src={"/images/second.png"}
                    alt="Build"
                  />
                  <p style={{ width: "100%", padding: "15px" }}>
                    Madelon then connects you to one of its pre-vetted housing
                    manufacturing partners.
                  </p>
                </div>
                <div
                  style={{
                    padding: "25px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <img
                    style={{ height: "250px" }}
                    src={"/images/third.png"}
                    alt="Build"
                  />
                  <p style={{ width: "100%", padding: "15px" }}>
                    Madelon provides support every step of the way to get you to
                    a finished building.
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
        {window.innerWidth >= 768 && (
          <div className="row">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                minHeight: { xs: "none", md: "100vh" },
                height: "100%",
                width: "100%",
                scrollSnapAlign: "start",
                textAlign: "center",
                padding: "25px",
                marginTop: { xs: "50px", md: "0px" },
                marginBottom: { xs: "50px", md: "0px" },
              }}
            >
              <h1 style={{ margin: "50px 0", padding: 0 }}>
                The Madelon Process
              </h1>
              <div
                style={{
                  display: "flex",
                  flexDirection: { xs: "column", md: "row" },
                  alignItems: "stretch",
                  justifyContent: "center",
                  gap: "35px",
                }}
              >
                <div
                  style={{
                    padding: "25px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    textAlign: "center",
                    flexBasis: "33%",
                  }}
                >
                  <img
                    style={{ maxWidth: "100%", height: "250px" }}
                    src={"/images/first.png"}
                    alt="Underwrite"
                  />
                  <p style={{ fontSize: "0.8em", width: "100%" }}>
                    Madelon provides an informed insight into a development
                    opportunity using one of its prepackaged housing solutions.
                  </p>
                </div>
                <div
                  style={{
                    padding: "25px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    textAlign: "center",
                    flexBasis: "33%",
                  }}
                >
                  <img
                    style={{
                      maxWidth: "100%",
                      height: "250px",
                    }}
                    src={"/images/second.png"}
                    alt="Build"
                  />
                  <p style={{ fontSize: "0.8em", width: "100%" }}>
                    Madelon then connects you to one of its pre-vetted housing
                    manufacturing partners.
                  </p>
                </div>
                <div
                  style={{
                    padding: "25px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    textAlign: "center",
                    flexBasis: "33%",
                  }}
                >
                  <img
                    style={{ maxWidth: "100%", height: "250px" }}
                    src={"/images/third.png"}
                    alt="Build"
                  />
                  <p style={{ fontSize: "0.8em", width: "100%" }}>
                    Madelon provides support every step of the way to get you to
                    a finished building.
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="row">
          <SlideIn>
            <h1 style={{ margin: 0, padding: 0, textAlign: "center" }}>
              A Mission Driven Company
            </h1>
          </SlideIn>
          <SlideIn>
            <p style={{ textAlign: "center", margin: "25px auto" }}>
              We see housing as a fundamental human right that can empower
              people and communities achieve great things and we believe this
              does not need to happen at the expense of the planet. That is why
              we catalyze the creation of housing that is…
            </p>
          </SlideIn>

          <SlideIn>
            {window.innerWidth < 768 && (
              <img
                style={{ width: "100%", padding: "55px 0" }}
                src={"/images/GM1.png"}
                alt="graphic"
              />
            )}
            {window.innerWidth >= 768 && (
              <img
                style={{ width: "100%", padding: "75px 0" }}
                src={"/images/G3.png"}
                alt="graphic"
              />
            )}

            <div style={{ textAlign: "center" }}>
              <button
                style={{
                  fontSize: "0.6em",
                  fontWeight: "300",
                  backgroundColor: "#eb4e32",
                  padding: "15px 25px",
                  color: "#fff",
                  border: "1px solid #eb4e32",
                }}
              >
                <a
                  href="/mission"
                  style={{
                    color: "#fff",
                    textAlign: "center",
                  }}
                >
                  Read More
                </a>
              </button>
            </div>
          </SlideIn>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            minHeight: "100vh",
            scrollSnapAlign: "start",
            background: `linear-gradient(to right, rgba(255,255,255,.5),
            rgba(229,228,225,.5) 100%), url("/images/0.jpg")`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            padding: "25px",
            fontWeight: 900,
            textAlign: "center",
          }}
        >
          <h1 style={{ margin: 0, padding: 0 }}>
            Madelon’s first productized Multifamily building is under
            development, breaking ground in Q4 ‘23
          </h1>
          <button
            style={{
              fontSize: "1em",
              fontWeight: "300",
              backgroundColor: "#eb4e32",
              padding: "15px 25px",
              color: "#fff",
              border: "1px solid #eb4e32",
              marginTop: "25px",
            }}
          >
            <a
              href="https://www.blueroomhouseone.com/"
              target="_blank"
              rel="noreferrer"
              style={{ color: "#fff" }}
            >
              Learn More About Blueroom House One
            </a>
          </button>
        </div>
      </div>

      {/*<div className="home-row center-text video-background-1 video-2">
                <div className="video-background-video">
                    <video playsInline loop autoPlay muted >
                        <source src="https://redtech-files.nyc3.cdn.digitaloceanspaces.com/videos/scene2.mp4" type='video/mp4'/>
                    </video>
                </div>
                <div className="content video-2-content">
                    <div className="home-row"></div>
                    <div className="home-row"></div>
                    <div className="home-row"></div>
                    <div className="home-row"></div>
                </div>
            </div>*/}

      <Footer active="0" />
    </>
  );
}

export default Home;
