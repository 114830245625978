import React from "react";
//import HeroBanner from '../components/heroBanner';
import Footer from "../components/footer";
import SlideIn from "../components/SlideIn";
import FadeIn from "../components/FadeIn";

import { Helmet } from "react-helmet";

class Developers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      doAnimation: "",
    };
    this.handleLoad = this.handleLoad.bind(this);
  }
  componentDidMount() {
    window.addEventListener("load", this.handleLoad);
  }
  componentWillUnmount() {
    window.removeEventListener("load", this.handleLoad);
  }
  handleLoad() {
    this.setState({
      doAnimation: (
        <>
          <div className="row">
            <SlideIn>
              <h5>Stop reinventing the wheel for every project.</h5>
              <p className="max-w-60">
                Madelon has productized development, meaning we balanced
                customization and standardization to make ground up residential
                projects more repeatable therefore reducing risk and shortening
                timelines.
              </p>
            </SlideIn>
          </div>
          <div className="row-image center-text">
            <FadeIn>
              <img
                src="/images/facade1.jpg"
                alt=""
                className="homepage-image"
              />
            </FadeIn>
          </div>
          <div className="row-hiw flex align-center">
            <div className="card-half-developer">
              <SlideIn>
                <h6>
                  Madelon REDtech takes developers from land to cash flow, and
                  everything in between
                </h6>
                <p>
                  By productizing development, we are reverse engineering the
                  entire development process. Working with our partner factories
                  first to develop the product, creating design packages so that
                  developers can choose the best product for the site (like in
                  the hospitality industry) and then inputting all of that
                  information into our REDtech platform so that developers can
                  evaluate and underwrite properties in minutes (a process that
                  normally takes weeks if not months and thousands of dollars to
                  complete).
                </p>
              </SlideIn>
              <FadeIn>
                <img
                  className="card-full-developer"
                  src="/images/HIW Drawings(2)-4.png"
                  alt=""
                />
              </FadeIn>
            </div>
            <div className="card-half-developer">
              <SlideIn>
                <p>
                  Madelon provides an integrated system of innovative solutions
                  and advanced technologies that work together to drive
                  increased business and keep costs down, without compromising
                  quality. We provide access to the latest tools, technologies,
                  forward-thinking strategies, and the most influential people
                  in the industry to create an unrivaled competitive advantage.
                </p>
              </SlideIn>
            </div>
          </div>
          <div className="row-hiw flex">
            <SlideIn>
              <h5>
                Madelon has a variety of products that meet the needs of the
                evolving American cities
              </h5>
              <p className="max-w-60">
                Whether building a new residential building, converting or
                renovating an existing one, our standards, prototypes, design
                guides and other supporting information enable owners,
                operators, developers and manufacturers to better understand
                each brand within the Madelon portfolio.
              </p>
            </SlideIn>
          </div>
          <div className="row-hiw flex align-center">
            <SlideIn>
              <div className="card-half-developer">
                <img
                  className="card-full-developer"
                  src="/images/HIW5.png"
                  alt=""
                />
              </div>
              <div className="card-half-developer">
                <h6>
                  Madelon is deeply invested in delivering products that are
                  prefab compatible
                </h6>
                <p>
                  We have been working with industry-leading modular firms to
                  explore this emerging technology and make it available to our
                  owners and developers. Modular construction can save owners
                  valuable time and provide potential pricing advantages over
                  more traditional building methods, while also improving
                  quality – delivering a win for owners and for tenants.
                </p>
              </div>
            </SlideIn>
          </div>
          <div className="row-hiw">
            <SlideIn>
              <h5>
                Our first product, flexible housing in New York is now open!
              </h5>
              <div className="card-half-developer">
                <div>
                  <p>
                    We created a housing brand,{" "}
                    <a
                      href="https://maisonmeanshome.com"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Maison
                    </a>
                    , that combines the predictable cash flow of the traditional
                    multifamily model with the flexibility of shorter term
                    hotel-like stays and enjoy the increased revenues and NOI of
                    Flex Multifamily, or as we like to call it, real estate’s
                    next investable asset class.
                  </p>
                  <p className="underline">
                    <a
                      href="https://maisonmeanshome.com"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Find out more about Maison
                    </a>
                  </p>
                </div>
              </div>
              <div className="card-half-developer">
                <img
                  className="card-full-developer"
                  src="/images/IMG_0888.jpg"
                  alt=""
                />
              </div>
            </SlideIn>
          </div>
        </>
      ),
    });
  }
  render() {
    return (
      <>
        <Helmet>
          <title>Madelon - Developers</title>
        </Helmet>
        <div className="image-wrapper">
          <img
            className="image-cover"
            src="./images/Developers Cover.png"
            alt=""
          />
        </div>
        <div className="">{this.state.doAnimation}</div>
        <Footer active="3" />
      </>
    );
  }
}

export default Developers;
