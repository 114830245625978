import React from "react";
import ContactForm from "../components/ContactForm";
import Footer from "../components/footer";
import BannerCTA from "../components/bannerCTA";

import { Helmet } from "react-helmet";
import SlideIn from "../components/SlideIn";

function Contact() {
  return (
    <>
      <Helmet>
        <title>Madelon - Contact</title>
      </Helmet>
      <div className="secondary-page pt80">
        <div className="row-contact">
          <div className="border-right card-half">
            <SlideIn>
              <h5 className="mt0">
                New features and models of housing are in the works
              </h5>
            </SlideIn>
            <div className="flex">
              <SlideIn>
                <h6>Contact us</h6>
                <a href="mailto:hello@madelongroup.com">
                  hello@madelongroup.com
                </a>
              </SlideIn>
            </div>
          </div>
          <div className="card-half contact-card">
            <SlideIn>
              <ContactForm />
            </SlideIn>
          </div>
        </div>
      </div>
      <Footer active="6" />
      <BannerCTA />
    </>
  );
}

export default Contact;
